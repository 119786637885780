import React, { useEffect, useState } from 'react';
import SkeletonTable from 'components/organisms/buy-credits/modals/wager-details/details-table/tables/skeleton';
import 'components/organisms/see-wager-details/modals/styles.scss';
import { formatDate, toCurrency } from 'common/bspot-shared';
import {
  getRaceResults,
  getRaceResultsNPP,
} from 'services/wager-transactions/wager-transactions';
import { useAuth } from 'contexts/authentication';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

const Scroll = (props) => {
  return (
    <div id='scroll' style={{ overflowY: 'scroll', height: '450px' }}>
      {props.children}
    </div>
  );
};

type WagerRaceTableProps = {
  raceId: any;
  raceResultsSb?: any;
};
export const WagerRaceTable = ({
  raceId,
  raceResultsSb,
}: WagerRaceTableProps) => {
  const { auth } = useAuth();
  const [raceResults, setRaceResults] = useState(raceResultsSb);
  useEffect(() => {
    if (raceId) {
      if (FeatureFlagsService.featureFlags?.enableNpp) {
        getRaceResultsNPP(raceId, auth.session).then((d) => {
          setRaceResults(d);
        });
      } else {
        getRaceResults(raceId, auth.session).then((d) => {
          setRaceResults(d);
        });
      }
    }
  }, [raceId, auth]);

  const isLast = (list, element) => element === list[list.length - 1];

  const formatResults = (value) => {
    if (value === 0) return '';
    return toCurrency(value);
  };

  if (FeatureFlagsService.featureFlags?.enableNpp) {
    return (
      <>
        <Scroll
          className={'py-2'}
          style={{ height: '450px', overflowY: 'hidden' }}
        >
          {raceResults ? (
            <table>
              <thead className='table-header'>
                <tr className={''}>
                  <th colSpan={2}>
                    {raceResults.track_name} - Race {raceResults.race_number}
                  </th>
                  <td colSpan={1} />
                  <th colSpan={2}>
                    {formatDate(
                      raceResults.date.year +
                        '-' +
                        raceResults.date.month +
                        '-' +
                        raceResults.date.day,
                    )}
                  </th>
                </tr>
              </thead>
              <thead className='race-table-header-title'>
                <tr>
                  <th colSpan={5} scope='colgroup' className={'claim'}>
                    Finishers
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className='race-table-header-cols'>
                  <td scope='col' className='fixed-width'>
                    POSITION
                  </td>
                  <td scope='col' className=''>
                    ENTRY (BETTING NUMBER)
                  </td>
                </tr>

                {raceResults.finishers?.map((result, i) => (
                  <tr className='table-row-2' key={i}>
                    <td className='fixed-width'> {result.position}</td>
                    <td className='fixed-width pl-5'>
                      {result.betting_number}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={5}>SCRATCHES: {raceResults.scratches}</td>
                </tr>
                <tr className=''>
                  <td scope='col' className='fw-bold'>
                    {raceResults.pool_name.toUpperCase() + ' PAYOUTS'}
                  </td>
                </tr>
                <tr className='race-table-header-cols'>
                  <td scope='col' className='fixed-width'>
                    BASE WAGER
                  </td>
                  <td scope='col' className='fixed-width'>
                    SELECTIONS
                  </td>
                  <td scope='col' className='fixed-width'>
                    PAYOUT
                  </td>
                </tr>
                {raceResults.payouts.map((prices, i) => (
                  <tr
                    className='table-row-2'
                    key={i + new Date().toISOString()}
                  >
                    <td scope='col' className='fixed-width pl-5'>
                      {toCurrency(+prices?.base_wager_curr / 100)}
                    </td>
                    <td scope='col' className='fixed-width pl-5'>
                      {prices?.selections}
                    </td>
                    <td scope='col' className='fixed-width pl-5'>
                      {toCurrency(+prices?.payout_curr / 100)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <SkeletonTable />
          )}
        </Scroll>
        {raceResults && (
          <div className={'d-flex flex-row justify-content-end '}>
            <span className={'poolId rounded-pill'}>{raceResults?.poolId}</span>
          </div>
        )}
      </>
    );
  } else {
    return (
      <Scroll
        className={'py-2'}
        style={{ height: '350px', overflowY: 'hidden' }}
      >
        {raceResults ? (
          <table>
            <thead className='table-header'>
              <tr className={''}>
                <th colSpan={2}>{raceResults?.race_name}</th>
                <td colSpan={1} />
                <th colSpan={2}>{raceResults?.date}</th>
              </tr>
            </thead>
            <thead className='race-table-header-title'>
              <tr>
                <th colSpan={5} scope='colgroup' className={'claim'}>
                  *-pp: Following track code indicates wagers were placed via
                  Private Pool
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className='race-table-header-cols'>
                <td scope='col' className='fixed-width'>
                  #
                </td>
                <td scope='col' className='fixed-width'>
                  HORSE
                </td>
                {raceResults.pool_names.map((poolName, index) => (
                  <td scope='col' className='fixed-width' key={index}>
                    {poolName}
                  </td>
                ))}
              </tr>

              {raceResults.runner_results?.map((result, i) => (
                <tr className='table-row' key={i}>
                  <td className='fixed-width'> {result.display_order}</td>
                  <td className='fixed-width '>{result.horse_number}</td>
                  {result.pool_results.map((pool_result, j) => (
                    <td className='fixed-width' key={j}>
                      {formatResults(pool_result.paid_amount)}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td colSpan={5}>
                  SCRATCHES:
                  {raceResults.scratches.map(
                    (scratch, i) =>
                      isLast(raceResults.scratches, scratch) && (
                        <span key={i}> {scratch} </span>
                      ),
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <SkeletonTable />
        )}
      </Scroll>
    );
  }
};

export default WagerRaceTable;
