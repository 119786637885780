import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'contexts/authentication';
import { getKYCAttempts } from 'services/kyc/kyc';

export const useKycStatus = () => {
  const { updateKyc, auth } = useAuth();

  return useQuery({
    queryKey: ['kyc-status', auth.session?.playerId],
    queryFn: async () => {
      const kyc = await getKYCAttempts();
      await updateKyc(kyc);
      return kyc;
    },
    keepPreviousData: false,
    enabled: auth.status?.validator_status !== 1 && !!auth.session?.playerId,
  });
};
