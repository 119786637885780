import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export const injectLiveChat = () => {
  return new Promise((resolve) => {
    if (document.getElementById('livechat-script')) {
      return resolve(true);
    }
    const scriptTag = document.createElement('script');
    scriptTag.id = 'livechat-script';
    scriptTag.src = 'https://apps.mypurecloud.com/widgets/9.0/cxbus.min.js';
    document.body.appendChild(scriptTag);
    return resolve(true);
  });
};
export const injectSalesforceLiveChat = () => {
  return new Promise((resolve) => {
    if (document.getElementById('salesforce-livechat-script')) {
      return resolve(true);
    }
    const scriptTag2 = document.createElement('script');
    scriptTag2.id = 'salesforce-livechat-script';
    scriptTag2.innerHTML = `
    
      window.initESW = function (gslbBaseURL) {
        window.embedded_svc.settings.displayHelpButton = false; //Or false
        window.embedded_svc.settings.language = 'en-US';
        window.embedded_svc.settings.storageDomain = window.location.host;
    window.embedded_svc.settings.smallCompanyLogoImgURL =
      'https://www.horseplay.com/assets/images/horseplay-mobile.svg';
        window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
        window.embedded_svc.settings.entryFeature = 'LiveAgent';
        
        window.embedded_svc.init(
        
        ${
          FeatureFlagsService.featureFlags.environment === 'stage'
            ? `'https://bspot--sandbox1.sandbox.my.salesforce.com',
                'https://bspot--sandbox1.sandbox.my.salesforce-sites.com/VIPAgentSetupFlow',
                gslbBaseURL,
                '00DOv00000AJCCc',
                'Live_Chat',
                {
                  baseLiveAgentContentURL: 'https://c.la2s-core1.sfdc-yfeipo.salesforceliveagent.com/content',
                  deploymentId: '572Hp000000Q3N5',
                  buttonId: '573Hp000000Q4fO',
                  baseLiveAgentURL: 'https://d.la2s-core1.sfdc-yfeipo.salesforceliveagent.com/chat',
                  eswLiveAgentDevName: 'Live_Chat',
                  isOfflineSupportEnabled: false,
                } 
                `
            : `'https://bspot.my.salesforce.com',
                'https://bspot.my.salesforce-sites.com/VIPAgentSetupFlow',
                gslbBaseURL,
                '00DHp000002YHlo',
                'Live_Chat',
                {
                  baseLiveAgentContentURL: 'https://c.la1-core1.sfdc-yfeipo.salesforceliveagent.com/content',
                  deploymentId: '572Hp000000Q3N5',
                  buttonId: '573Hp000000Q4fO',
                  baseLiveAgentURL: 'https://d.la1-core1.sfdc-yfeipo.salesforceliveagent.com/chat',
                  eswLiveAgentDevName: 'Live_Chat',
                  isOfflineSupportEnabled: false,
                }`
        }
        );
      };

      if (!window.embedded_svc) {
        var s = document.createElement('script');
         
        ${
          FeatureFlagsService.featureFlags.environment === 'stage'
            ? `s.setAttribute('src', 'https://bspot--sandbox1.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js')`
            : `s.setAttribute('src', 'https://bspot.my.salesforce.com/embeddedservice/5.0/esw.min.js')`
        };
        s.onload = function () {
          window.initESW(null);
        };
        document.body.appendChild(s);
        window.Bspot.liveChat = {
          open: () => {
           window.embedded_svc.bootstrapEmbeddedService().then(() => {
              if(window.Bspot.auth?.session) {
                document.getElementsByClassName('inputSplitName')[0].style.display = 'none';
                document.getElementsByClassName('inputSplitName')[1].style.display = 'none';
                document.getElementsByClassName('inputEmail')[0].style.display = 'none';
                document.getElementsByClassName('inputText')[0].style.display = 'none';
              }
           });
          },
          close: () => {

          },

        }
      } else {
        window.initESW('https://service.force.com');
        window.Bspot.liveChat = {
          open: () => {
           window.embedded_svc.bootstrapEmbeddedService().then(() => {
              if(window.Bspot.auth?.session) {
                document.getElementsByClassName('inputSplitName')[0].style.display = 'none';
                document.getElementsByClassName('inputSplitName')[1].style.display = 'none';
                document.getElementsByClassName('inputEmail')[0].style.display = 'none';
                document.getElementsByClassName('inputText')[0].style.display = 'none';
              }
           });
          },
          close: () => {

          },

        }
      }
    `;
    document.body.appendChild(scriptTag2);
    return resolve(true);
  });
};
