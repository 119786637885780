import styled from '@emotion/styled';
import { Text } from 'components/atoms/text';
import { ToastStatus } from 'components/organisms/toasts/toast';
import { useSnackbar } from 'contexts/snackbar';
import { WSMessage } from 'hooks/use-webpush-listener';

const SnackbarTitle = styled(Text)`
  font-weight: 600;
`;

export const useShowCreditsReadyToast = () => {
  const { push, has } = useSnackbar();

  const showCreditsBoughtToast = (message: WSMessage) => {
    if (has(message.id)) return;

    const amount = message?.data?.parameters?.amount / 100;

    push({
      title: (
        <SnackbarTitle>
          Your <b>${amount}</b> Credits are ready to play!
        </SnackbarTitle>
      ),
      id: message.id,
      status: ToastStatus.success,
      persist: true,
      icon: <img src='/assets/images/thumb-sparkle.svg' alt='' />,
    });
  };

  return showCreditsBoughtToast;
};
