import { Routes } from 'common/routes';
import { Button } from 'components/atoms/button';
import { useNavigate } from 'hooks/use-navigate';
import { Close, Cross } from 'icons/svg';
import { useAuth } from 'contexts/authentication';
import { BspotModal } from 'components/atoms/modal/modal';
import React from 'react';
import { useSdk } from 'contexts/sdk/sdk';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';
import { Link } from 'components/atoms/link';

const registrationFailMessages = new Map<number, string>();
registrationFailMessages.set(
  5,
  'We’re having trouble verifying the information you submitted. Please\n' +
    '            review the details you entered and make certain they match your\n' +
    '            official identification documents such as a Driver License, State\n' +
    '            ID, or Passport. You have two chances to edit your information.',
);
registrationFailMessages.set(
  6,
  'We are unable to verify your identity with the information provided.\n' +
    '            This can happen occasionally for a variety of reasons. But all is\n' +
    '            not lost. You can easily upload documentation to help us verify your\n' +
    '            age and state of residence. Please open the Documents Center in the\n' +
    '            menu to get started. This only takes a minute or two.',
);

export type KycProps = {
  onclose?(): void;
  show: boolean;
  status: number;
};
export const RegistrationFail = ({ status, show, onclose }: KycProps) => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { kyc } = auth;
  const { getEmbedded } = useSdk();
  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Personal Details Verification'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onclose} />
      </div>

      <div className={'d-flex flex-column gap-1'}>
        <div>
          {registrationFailMessages.get(status)}

          {getEmbedded && (
            <div className={'fw-bold'}>
              <br />
              Please head to our website to verify your identity. &nbsp;
              <br />
              <Link
                text={'https://www.horseplay.com'}
                href={'https://bspot.com'}
                className={'py-3'}
              >
                {' '}
              </Link>
            </div>
          )}
        </div>
      </div>

      {!getEmbedded && (
        <div
          className={
            'd-flex flex-lg-row flex-column gap-2 justify-content-center'
          }
        >
          <Button
            disabled={kyc?.attempts === kyc?.max_attempts}
            text={kyc && `Edit My info ${kyc?.attempts}/${kyc?.max_attempts}`}
            onClick={() => {
              onclose();
              if (getEmbedded) {
                postNativeMessage(
                  BspotCashierMessage.KYC_FAIL,
                  'KYC verification failed : Edit Personal info',
                );
              } else {
                navigate(Routes.PlayerInfo);
              }
            }}
          />
          <Button
            disabled={kyc?.attempts < kyc?.max_attempts}
            text='Document Upload'
            onClick={() => {
              onclose();
              if (getEmbedded) {
                postNativeMessage(
                  BspotCashierMessage.KYC_FAIL,
                  'KYC verification failed : Upload Documents',
                );
              } else {
                navigate(Routes.DocumentCenter.path);
              }
            }}
          />
        </div>
      )}
    </BspotModal>
  );
};
