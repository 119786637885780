import { useQuery } from '@tanstack/react-query';
import { Api } from 'common/api';
import { useAuth } from 'contexts/authentication';
import { GameProduct } from 'services/get-game-products/get-game-products';

type BonusModalInfo = {
  environment: string;
  premiumStates: string[];
  silverBonus: any;
  silverBonusCta: string;
  silverBonusPremium: any;
  silverBonusPremiumCta: string;
  goldBonus: any;
  goldBonusCta: string;
  goldBonusPremium: any;
  goldBonusPremiumCta: string;
  platinumBonus: any;
  platinumBonusCta: string;
  platinumBonusPremium: any;
  platinumBonusPremiumCta: string;
  emeraldBonus: any;
  emeraldBonusCta: string;
  emeraldBonusPremium: any;
  emeraldBonusPremiumCta: string;
  rubyBonus: any;
  rubyBonusCta: string;
  rubyBonusPremium: any;
  rubyBonusPremiumCta: string;
};

const getBonusModalInfo = async () => {
  const { data, ok } = await Api.get<BonusModalInfo>(
    `${API_HOST}/api/v1/bonus-modal?env=${BUILD_ENV}`,
  );

  if (!ok)
    throw new Error('An error occurred while fetching the bonus modal info.');

  return data;
};

const bonusNames: Record<
  number,
  Exclude<keyof BonusModalInfo, 'environment' | 'premiumStates'>
> = {
  10: 'silverBonus',
  20: 'goldBonus',
  40: 'platinumBonus',
  100: 'emeraldBonus',
  250: 'rubyBonus',
};

export const useBonusModalInfo = (product: GameProduct) => {
  const { auth } = useAuth();
  const { data, isLoading, isError } = useQuery(
    ['bonus-modal', auth?.session?.state],
    {
      queryFn: getBonusModalInfo,
      refetchOnWindowFocus: false,
    },
  );

  const isInPremiumState = data?.premiumStates?.some(
    (state) => state === auth?.session?.state,
  );

  const key = isInPremiumState
    ? bonusNames[product.cost] + 'Premium'
    : bonusNames[product.cost];
  const terms = data?.[key];
  const buttonUrl = data?.[key + 'Cta'];

  return { terms, buttonUrl, isLoading, isError };
};
