import { BspotModal } from '../../../../atoms/modal/modal';
import { Close } from '../../../../../icons/svg';
import styled from '@emotion/styled';

export const TermsAndConditionsModal = ({ show, setShow, tnc }) => {
  return (
    <BspotModal show={show} maxHeight={'60vh'}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Terms and Conditions'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={setShow} />
      </div>
      <ModalContentTsnCs>
        <div
          style={{
            overflow: 'auto',
            paddingBottom: '20px',
          }}
          dangerouslySetInnerHTML={{ __html: tnc }}
        />
      </ModalContentTsnCs>
    </BspotModal>
  );
};

export const ModalContentTsnCs = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 20px;
  height: 60vh;
  padding-left: -40px;

  animation: mask-up;
  animation-timeline: scroll(self);

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  #scroll::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  border-radius: 10px;
  border: 0.5px solid rgba(68, 68, 68, 1);

  ol {
    margin: 0 !important;
  }
`;
