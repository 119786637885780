import React, { useState } from 'react';
import { Close } from 'icons/svg';
import { Button, OutlinedButton } from 'components/atoms/button';
import { toCurrency } from 'common/bspot-shared';
import WagerDetailsTable from 'components/organisms/see-wager-details/modals/wager-details-table';
import WagerBonusTable from 'components/organisms/see-wager-details/modals/wager-bonus-table';
import { BspotModal } from 'components/atoms/modal/modal';
import WagerRaceTable from 'components/organisms/see-wager-details/modals/wager-race-table';

export type SeeWagerDetailsModalProps = {
  onClose?(): void;
  transactions: any;
  show: boolean;
};
export const SeeWagerDetailsModal = ({
  onClose,
  show,
  transactions,
}: SeeWagerDetailsModalProps) => {
  const [raceId, setRaceId] = useState(null);
  const onCloseModal = () => {
    onClose();
  };

  const viewRaceId = (raceId) => {
    setRaceId(raceId);
  };

  const wagerDetailsResume = (
    <div className={'container'}>
      <div className='row gx-4 '>
        <div className='col-6' data-testid='total-wagered'>
          <div
            className={'p-2 d-flex flex-column'}
            style={{
              backgroundColor: 'rgba(68, 68, 68, 1)',
              borderRadius: '4px',
              fontSize: '11px',
            }}
          >
            <span>Total Wagered</span>
            <strong className=''>
              {' '}
              {`${toCurrency(transactions?.totalWagered)}`}{' '}
            </strong>
          </div>
        </div>
        <div className='col-6' data-testid='total-won'>
          <div
            className={'p-2 d-flex flex-column'}
            style={{
              backgroundColor: 'rgba(68, 68, 68, 1)',
              borderRadius: '4px',
              fontSize: '11px',
            }}
          >
            <span>Total Won/Credit</span>
            <strong className='value'>
              {`${toCurrency(transactions?.totalCredits)}`}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <BspotModal show={show} smallLg={true} maxHeight={'95vh'}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>
          {raceId ? 'Wager Pool Results' : 'Wager Details'}
        </h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
      </div>
      <div
        id='scroll-no-border'
        style={{ overflowY: 'scroll' }}
        className={'d-flex flex-column gap-2'}
      >
        {!raceId && (
          <>
            {wagerDetailsResume}
            {transactions?.totalBonus > 0 && (
              <WagerBonusTable bonusAmount={transactions?.totalBonus} />
            )}
            <WagerDetailsTable data={transactions} onViewRaceId={viewRaceId} />
            <div className={'d-flex flex-lg-row flex-col flex-column gap-2 '}>
              <Button text={'Ok, got it!'} onClick={onCloseModal} />
            </div>
          </>
        )}
        {raceId && (
          <>
            <WagerRaceTable raceId={raceId} />
            <div className={'d-flex flex-lg-row flex-col flex-column gap-2 '}>
              <OutlinedButton text={'back'} onClick={() => setRaceId(null)} />
            </div>
          </>
        )}
      </div>
    </BspotModal>
  );
};
export default SeeWagerDetailsModal;
