import 'common/theme/main.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetBalance } from 'hooks/use-balance';
import GameProductList from 'components/organisms/buy-credits/game-product-list/game-product-list';
import { Routes } from 'common/routes';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'hooks/use-navigate';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
import { BspotAlert, SectionTitle } from 'components';
import { GameProduct } from 'services/get-game-products/get-game-products';
import { useAuth } from 'contexts/authentication';
import { setCreditsBought } from 'hooks/use-freeplay-tracker';
import SelectedGameProduct from 'components/organisms/buy-credits/game-product-selected/selected-game-product';
import WagerDetails from 'components/organisms/buy-credits/modals/wager-details/wager-details';
import BonusModal from 'components/organisms/buy-credits/modals/bonus-modal';
import PlayLaterModal from 'components/organisms/buy-credits/modals/play-later-modal';
import InsufficientFundsModal from 'components/organisms/buy-credits/modals/insufficient-funds-modal';
import WarningModal from 'components/organisms/buy-credits/modals/warning-modal';
import DepositFirstModal from 'components/organisms/buy-credits/modals/deposit-first-modal';
import { DelayedPurchaseModal } from 'components/organisms/buy-credits/modals/delayed-purchase-modal';
import confetti from 'canvas-confetti';
import {
  buyCredits,
  BuyCreditsPayload,
} from 'services/buy-credits/buy-credits';
import { useSdk } from 'contexts/sdk/sdk';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';
import { useBalanceAndCredits } from 'hooks/use-balance-and-credits/use-balance-and-credits';
import NoLocationAvailableModal from 'components/organisms/buy-credits/modals/no-location-available.modal';
import { sendGTMEvent } from 'services/_gtm-analytics/gtm-analytics';
import { sendAppsFlyerEvent } from 'services/_af-events/appsflyer';
import {
  addCreditsToCartAnalyticsEvent,
  creditPurchaseAnalyticsEvent,
  lazyWithRetry,
} from 'common/utils';
import LocationNotAllowedModal from 'components/organisms/buy-credits/modals/location-not-allowed.modal';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { FadeInContainer } from 'pages/make-deposit/styled';
import { toCurrency } from 'common/bspot-shared';
import { SdkActions } from 'contexts/game-sdk/game-sdk';
import { useProductsAndBalances } from '../../hooks/use-products-and-balances/use-products-and-balances';

const DisabledWagersPage = lazyWithRetry(() => import('./disabled-wagers'));

export type WagersPageProps = {
  isModal: boolean;
  closeAll?(): void;
  instantPackage?: GameProduct;
  onOpenQuickCredits?(): void;
};

export default function WagersPage({
  closeAll,
  instantPackage,
  isModal = false,
  onOpenQuickCredits,
}: WagersPageProps) {
  const { getEmbedded } = useSdk();
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<GameProduct | null>(null);
  const [showBonus, setShowBonus] = useState(false);
  const [showSelected, setShowSelected] = useState(false);
  const [showPlayLater, setShowPlayLater] = useState(false);
  const [showNotEnoughCash, setShowNotEnoughCash] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showWagerDetails, setShowWagerDetails] = useState(false);
  const [showDelayedPurchaseModal, setShowDelayedPurchaseModal] =
    useState(false);

  const [showDepositFirst, setShowDepositFirst] = useState(false);
  const [showNoLocationAvailable, setShowNoLocationAvailable] = useState(false);

  const [showLocationNotAllowed, setShowLocationNotAllowed] = useState(false);

  const [transactionId, setTransactionId] = useState(null);
  const [incomeAccess, setIncomeAccess] = useState(false);

  const { auth } = useAuth();
  const { data: status } = usePlayerStatus();
  const { refetch: refetchBalances } = useGetBalance();
  const { gameProducts, balances, instantPlayEligible } =
    useProductsAndBalances();

  const [searchParams] = useSearchParams();
  const deposit = searchParams.get('deposit');
  const inGame = location.pathname.includes(Routes.Game.path);
  const isIOS = localStorage.getItem('isIOS');

  useEffect(() => {
    if (deposit) {
      localStorage.removeItem('depositEvent');
      const depositCount = localStorage.getItem('deposit_count');
      +depositCount === 0 && showConfetti();
    }

    resetStates();
  }, [deposit, location]);

  const noInstantGamesAvailable = useMemo(() => {
    return (
      gameProducts?.filter((gp) => gp.expected_wait === 'IMMEDIATE').length ===
      0
    );
  }, [gameProducts]);

  const resetStates = () => {
    setSelected(null);
    setShowBonus(false);
    setShowSelected(false);
    setShowPlayLater(false);
    setShowNotEnoughCash(false);
    setShowWarning(false);
    setShowWagerDetails(false);
    setShowDepositFirst(false);
  };

  const onClickBonus = (product: GameProduct) => {
    setSelected(product);
    setShowBonus(true);
  };

  const onClickProduct = (product: GameProduct) => {
    if (balances.cash < product.cost) {
      setShowNotEnoughCash(true);
      return;
    }
    const depositCount = localStorage.getItem('deposit_count');
    if (status?.validator_status === 1 && +depositCount === 0) {
      setShowDepositFirst(true);
      return;
    }
    setSelected(product);

    addCreditsToCartAnalyticsEvent({
      product,
      playerId: auth.session.playerId,
    });

    if (product.expected_wait === 'IMMEDIATE' || noInstantGamesAvailable) {
      setShowSelected(true);
    }

    if (!noInstantGamesAvailable && product.expected_wait !== 'IMMEDIATE') {
      setShowPlayLater(true);
    }
  };

  const onSeeWagerDetailsFromSelected = (product: GameProduct) => {
    if (product?.expected_wait === 'IMMEDIATE') {
      setShowWarning(!showWarning);
    } else {
      setShowWagerDetails(true);
    }
  };
  useEffect(() => {
    if (instantPackage) {
      setSelected(instantPackage);
      // setShowSelected(true);
      if (instantPackage?.expected_wait === 'IMMEDIATE') {
        setShowWarning(true);
      } else {
        setShowWagerDetails(true);
      }
    }
  }, [instantPackage]);

  const onCloseWagerDetails = () => {
    setShowWagerDetails(false);
    if (instantPackage) {
      onOpenQuickCredits();
    }
  };

  const onClickPlayLaterFromPlayLater = () => {
    setShowSelected(true);
  };

  const onClickPlayLaterFromShowWarning = (prod: GameProduct) => {
    setSelected(prod);
    setShowWarning(false);
    setShowWagerDetails(true);
  };

  const onClickBonusFromSelected = () => {
    setShowBonus(true);
  };

  const onConfirmedPurchase = async (
    product,
    wagerDetailsId?,
    replaceWagers?,
  ) => {
    //place order
    try {
      let payload: BuyCreditsPayload = {
        game_product_id: selected.id,
      };
      if (wagerDetailsId) {
        payload = {
          ...payload,
          wager_package_id: wagerDetailsId,
          replace_unavailable_requested_wagers: !replaceWagers,
        };
      }

      const creditCount = balances?.credits_purchased_count === 0;
      const hasAlreadyDoneFCP =
        localStorage.getItem('fcp-' + auth.session.playerId) === '1';

      const res = await buyCredits(payload, auth.session);
      if (res?.status === 403) {
        setShowLocationNotAllowed(true);
        return;
      }
      const d = new Date().toISOString();
      setTransactionId(auth?.session?.playerId + '-' + d);

      setIncomeAccess(
        FeatureFlagsService?.featureFlags?.enableIncomeAccess && creditCount,
      );

      if (getEmbedded) {
        postNativeMessage(
          BspotCashierMessage.BUY_CREDITS_SUCCESS,
          JSON.stringify(selected),
        );
        postNativeMessage({
          name: 'package_revenue',
          value: product.cost * (6 / 100),
        });
      }

      creditPurchaseAnalyticsEvent({
        selectedPackage: product,
        playerId: auth?.session?.playerId,
      });

      if (creditCount && !hasAlreadyDoneFCP) {
        localStorage.setItem('fcp-' + auth.session.playerId, '1');
        postNativeMessage({
          name: 'first_credit_purchase',
          value: product.cost,
        });
      }

      if (inGame) {
        setShowSelected(false);
        closeAll();
      } else if (selected.expected_wait !== 'IMMEDIATE' || wagerDetailsId) {
        setShowDelayedPurchaseModal(true);
      } else {
        postNativeMessage(
          BspotCashierMessage.CLOSE,
          'CLOSE Buy Credits Success',
        );
      }
      setCreditsBought(selected.cost, auth.auth?.playerId);
      setShowWagerDetails(false);

      return;
    } catch (error) {
      console.log(error);
      if (error?.ok === false && error?.data?.latitude === 0) {
        setShowNoLocationAvailable(true);
      }
      return error.message;
    }
  };

  const onCloseDelayedPurchaseModal = () => {
    setShowDelayedPurchaseModal(false);
    setSelected(null);
    setShowSelected(false);
    postNativeMessage(
      BspotCashierMessage.CLOSE,
      'CLOSE Delayed purchase modal',
    );
  };

  const onCloseNoLocationAvailable = () => {
    setShowNoLocationAvailable(false);
  };

  const onCloseLocationNotAllowed = () => {
    setShowLocationNotAllowed(false);
  };

  const onGoMakeDeposit = () => {
    if (location.pathname === Routes.Game.path) {
      closeAll();
      document.getElementById('horseplay-cash-button').click();
      return;
    } else {
      navigate(Routes.MakeDepositPage.path);
    }
  };

  const disableWagers = useMemo(() => {
    if (!FeatureFlagsService?.featureFlags) return false;

    const now = new Date();
    const isInTimeRange =
      now >=
        new Date(FeatureFlagsService?.featureFlags?.disabledWagersStartDate) &&
      now <= new Date(FeatureFlagsService?.featureFlags?.disabledWagersEndDate);

    return (
      isInTimeRange &&
      FeatureFlagsService?.featureFlags?.disabledWagersStates?.includes(
        auth.session?.state,
      )
    );
  }, [FeatureFlagsService?.featureFlags]);

  const showConfetti = () => {
    confetti({
      particleCount: 450,
      spread: 80,
      startVelocity: 130,
      origin: { x: 0, y: 1.3 },
    });
    confetti({
      particleCount: 450,
      spread: 80,
      startVelocity: 130,
      origin: { x: 1, y: 1.3 },
    });
  };

  if (disableWagers) return <DisabledWagersPage />;

  return (
    <div>
      <div className='col-12 col-sm-9 col-md-12 mx-auto pt-1s  d-flex flex-column'>
        {deposit && (
          <>
            <FadeInContainer
              className={`d-flex col-12 flex-column mx-auto pb-3 ${
                isModal ? 'mb-3' : ''
              }`}
            >
              <BspotAlert
                type={'grey'}
                message={
                  <>
                    <div>
                      <span className={'fw-bold'}>
                        You successfully deposited
                      </span>{' '}
                      <span className={'text-primary fw-bold'}>
                        {toCurrency(deposit)}
                      </span>
                      <br />
                      <span className={''}>Time to buy some credits!</span>
                    </div>
                  </>
                }
              />
            </FadeInContainer>
          </>
        )}

        {!isIOS && <SectionTitle title='Buy Credits' />}
        {isIOS && <div className={'pt-1'}>&nbsp;</div>}
        {!showSelected && !instantPackage && (
          <GameProductList
            onClickProduct={onClickProduct}
            products={gameProducts}
            onClickBonus={onClickBonus}
            inGame={inGame}
            noInstantGamesAvailable={noInstantGamesAvailable}
          />
        )}
        {showSelected && !showWagerDetails && (
          <SelectedGameProduct
            instantPlayEligible={instantPlayEligible}
            onClickBadge={onClickBonusFromSelected}
            product={selected}
            isModal={isModal}
            onSeeWagerDetails={(e) => onSeeWagerDetailsFromSelected(e)}
            onConfirm={onConfirmedPurchase}
          />
        )}

        {showWagerDetails && (
          <WagerDetails
            show={showWagerDetails}
            instantPlayEligible={instantPlayEligible}
            product={selected}
            onClickBadge={onClickBonusFromSelected}
            onClose={onCloseWagerDetails}
            onConfirm={onConfirmedPurchase}
          />
        )}
      </div>
      {showBonus && (
        <BonusModal
          show={showBonus}
          product={selected}
          onClose={() => setShowBonus(false)}
        />
      )}

      {showPlayLater && (
        <PlayLaterModal
          show={showPlayLater}
          product={selected}
          onClickPlayLater={onClickPlayLaterFromPlayLater}
          onClose={() => setShowPlayLater(false)}
        />
      )}

      {showNotEnoughCash && (
        <InsufficientFundsModal
          show={showNotEnoughCash}
          onGoDeposit={onGoMakeDeposit}
          onClose={() => setShowNotEnoughCash(false)}
        />
      )}

      {showWarning && (
        <WarningModal
          show={showWarning}
          product={selected}
          onClickPlayLater={onClickPlayLaterFromShowWarning}
          onClose={() => {
            setShowWarning(false);
            if (isModal && instantPackage) {
              onOpenQuickCredits();
            }
          }}
        />
      )}

      {showDepositFirst && (
        <DepositFirstModal
          onGoDeposit={onGoMakeDeposit}
          show={showDepositFirst}
          onClose={() => setShowDepositFirst(false)}
        />
      )}

      {showDelayedPurchaseModal && (
        <DelayedPurchaseModal
          show={showDelayedPurchaseModal}
          gameProduct={selected}
          onClose={onCloseDelayedPurchaseModal}
        />
      )}
      {showNoLocationAvailable && (
        <NoLocationAvailableModal
          show={showNoLocationAvailable}
          onClose={onCloseNoLocationAvailable}
        />
      )}
      {showLocationNotAllowed && (
        <LocationNotAllowedModal
          show={showLocationNotAllowed}
          onClose={onCloseLocationNotAllowed}
        />
      )}
      {incomeAccess && (
        <iframe
          style={{ display: 'none' }}
          src={`https://tracking.bspot.com/Processing/Pixels/Sales.ashx?PlayerID=${auth?.session?.playerId}&mid=2&saleid=${transactionId}`}
        ></iframe>
      )}
    </div>
  );
}
