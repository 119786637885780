import styled from '@emotion/styled';
import { getGameProductTransactions } from 'services/wager-transactions/wager-transactions';
import { useState } from 'react';
import SeeWagerDetailsModal from 'components/organisms/see-wager-details/modals/wager-details';
import WagerDetailsLoadingModal from 'components/organisms/see-wager-details/modals/wager-details-loading';
import WagerDetailsNotAvailableModal from 'components/organisms/see-wager-details/modals/wager-details-not-available';
import WagerDetailsSpoilerModal from 'components/organisms/see-wager-details/modals/wager-details-spoiler';
import { useAuth } from 'contexts/authentication';

export const SeeWagerDetailsSpan = styled.span`
  color: #d24f1c;
  cursor: pointer;
`;

export const SeeWagerDetails = ({ id }) => {
  const { auth } = useAuth();
  const [tx, setTx] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showSpoiler, setShowSpoiler] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showNoData, setShowNoData] = useState(false);

  const fetchTransactions = () => {
    setShowLoading(true);
    getGameProductTransactions(id, auth.session)
      .then((transactions) => {
        setTx(transactions);
        setShowLoading(false);
        setShowSpoiler(true);
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
        setShowNoData(true);
      });
  };

  const closeSpoiler = () => {
    setShowDetails(true);
    setShowSpoiler(false);
  };

  const close = () => {
    setShowDetails(false);
    setTx(null);
  };

  const closeNoData = () => {
    setShowNoData(false);
    setTx(null);
  };

  return (
    <>
      <SeeWagerDetailsSpan
        id={`wager-details-${id}`}
        data-testid={`wager-details-${id}`}
        className='details'
        onClick={fetchTransactions}
      >
        See wager details
      </SeeWagerDetailsSpan>
      {showSpoiler && (
        <WagerDetailsSpoilerModal
          show={showSpoiler}
          onClose={closeSpoiler}
          onGoBack={() => setShowSpoiler(false)}
        />
      )}
      {showDetails && (
        <SeeWagerDetailsModal
          show={showDetails}
          transactions={tx}
          onClose={close}
        />
      )}
      {showNoData && (
        <WagerDetailsNotAvailableModal
          show={showNoData}
          transactions={tx}
          onClose={closeNoData}
        />
      )}
      {showLoading && (
        <WagerDetailsLoadingModal
          show={showLoading}
          transactions={tx}
          onClose={close}
        />
      )}
    </>
  );
};

export default SeeWagerDetails;
